<template>
  <div class="login">正在链接，请稍后....</div>
</template>

<script>
import { AppModule } from "@/store/modules/app";
import * as utils from "@/utils";
import { ApiPostUserInfo } from "@/apis";
export default {
  data() {
    return {
      auth: "",
      url: "/home",
    };
  },
  created() {
    this.auth = this.$route.query.t;
    utils.set("auth", this.auth);
    if (this.$route.query.u) {
      this.url = this.$route.query.u;
    }
  },
  mounted() {
    this.underLogin();
  },
  methods: {
    async underLogin() {
      try {
        const result = await ApiPostUserInfo(2);
        if (result.success) {
          utils.set("auth", result.data.auth);
          utils.set("REQUEST-AUTH", "");
          utils.set("userId", result.data.id);
          utils.set("doctorLoginInfo", result.data);
          if (result.data.name) {
            utils.set("userName", result.data.name);
          }
          if (result.data.avater) {
            utils.set("headIcon", result.data.avater);
          }
          if (result.data.last_login_date) {
            utils.set("lastLoginTime", result.data.last_login_date);
          }
          if (result.data.login_ip) {
            utils.set("lastLoginIp", result.data.login_ip);
          }
          AppModule.ToggleFunctionGroupArray(result.data.menu_groups);
          this.$router.push(this.url);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 200px;
  text-align: center;
  font-size: 28px;
  min-height: 100vh;
}
</style>
